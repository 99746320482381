<template>
  <div>
    <el-row class="content-searcha">
      <el-form :inline="true" size="small">
        <el-form-item>
          <WarehouseMultSelector
            v-model="search.warehouseId"
            placeholder="请选择仓库"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.ec_id"
            placeholder="请选择业务类型"
            clearable
          >
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getSalesOutInList(true)"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <!-- <el-button
            type="primary"
            @click="$refs['export-dialog'].show('F03', type, search.ec_id)"
            >导出</el-button
          > -->
          <el-button
            type="primary"
            @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe :data="salesOutInlist" border width="100%">
        <el-table-column label="仓库单号">
          <template slot-scope="scope">
            {{ scope.row.order_id }}&nbsp;&nbsp;
            <el-tag>{{ scope.row.aino }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="warehouse" label="仓库"></el-table-column>
        <!-- <el-table-column prop="user_name" label="仓管"></el-table-column> -->
        <el-table-column prop="wms.updated_at" label="日期"></el-table-column>
        <el-table-column prop="platform" label="平台">
          <!-- <template slot-scope="scope">
            {{ scope.row.ec_id | getEcName(sysConfig.Common.ec) }}
          </template> -->
        </el-table-column>
        <el-table-column prop="no" label="外部单号"></el-table-column>
        <el-table-column label="MFN" width="200">
          <template slot-scope="{ row }">
            <div class="flex items-center">
              <p>{{ row.mfn }}</p>
              <el-tag v-if="row.is_new" class="ml-2">{{ row.is_new }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="product"
          label="品名"
          width="150"
        ></el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column label="成本价">
          <template slot-scope="{ row }">
            <span>{{ row.currency }} {{ row.cost }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cost_amount" label="成本金额"></el-table-column>
        <el-table-column label="销售单价">
          <template slot-scope="{ row }">
            <span>{{ row.wms_currency }} {{ row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="销售金额"></el-table-column>
        <el-table-column prop="exchange_rate" label="汇率"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <!-- <export-dialog ref="export-dialog"></export-dialog> -->
    <xlsx-downloader ref="xlsx-downloader" type="FINANCE_SALES_IN_OUT" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as warehouseBillApi from '@/api/warehouseBill'
import WarehouseMultSelector from '@/components/finance/warehouseBills/WarehouseMultSelector'
import XlsxDownloader from '@/components/xlsx-downloader'

export default {
  props: ['type', 'FinanceWMSSales'],
  components: {
    XlsxDownloader,
    WarehouseMultSelector
  },
  data () {
    return {
      search: {
        warehouseId: [],
        start: '',
        end: '',
        ec_id: ''
      },
      salesOutInlist: [],
      pageData: {
        current_page: 1,
        per_page: 100
      }
    }
  },
  filters: {
    getEcName (value, ec) {
      for (let i = 0; i < ec.length; i++) {
        if (ec[i].key === value) {
          return ec[i].val
        }
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      this.pageData.current_page = 1
      this.getSalesOutInList()
    }
  },
  created () {
    // this.getSalesOutInList()
  },
  methods: {
    handleExport () {
      // TODO: type = F03
      const params = {
        ec_id: this.search.ec_id,
        date_start: this.search.start,
        date_end: this.search.end,
        warehouse_id: this.search.warehouseId ? this.search.warehouseId.join(',') : null
      }
      this.$refs['xlsx-downloader'].show(params)
    },
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getSalesOutInList (searchflag = false) {
      const self = this
      if (searchflag === true) {
        self.pageData.current_page = 1
      }
      const listJsonData = {
        type: self.type,
        warehouse_id: self.search.warehouseId,
        date_start: self.search.start,
        date_end: self.search.end,
        ec_id: self.search.ec_id,
        page: self.pageData.current_page,
        per_page: self.pageData.per_page
      }
      warehouseBillApi
        .getSalesOutInList(listJsonData)
        .then(data => {
          self.salesOutInlist = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    downLoadLink () {
      const url =
        baseUrl +
        'finance/wms/exportSalesIO?token=' +
        localStorage.getItem('token') +
        '&type=' +
        this.type +
        '&warehouse_id=' +
        this.search.warehouseId.join(',') +
        '&date_start=' +
        this.search.start +
        '&date_end=' +
        this.search.end +
        '&ec_id=' +
        this.search.ec_id
      window.open(url)
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getSalesOutInList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getSalesOutInList()
    }
  }
}
</script>
