<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">仓库单据</el-breadcrumb-item>
        <el-breadcrumb-item>销售进出仓明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in tabs"
          :label="item.val"
          :key="item.key"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row>
      <sales-out-in-list
        :type="tabvalue"
        :FinanceWMSSales="FinanceWMSSales"
      ></sales-out-in-list>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import salesOutInList from './sales-out-in-list'
export default {
  components: {
    salesOutInList
  },
  data () {
    return {
      tabs: [],
      tabvalue: '-4',
      FinanceWMSSales: {}
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.FinanceWMSSales = this.sysConfig.FinanceWMSSales
    this.tabs = this.FinanceWMSSales.type
  }
}
</script>
